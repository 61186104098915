/* PUBLIC */

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20vh;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 30s linear;
  }
}

.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  font-size: calc(20px + 2vmin);
  color: white;
  flex-direction: column;
}
.app-header {
position: absolute;
padding-left: 10px;
}
.header-content {
  display: flex;
  justify-content: space-between; /* Align content to opposite ends */
}
.sign-in-out-container {

}
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
 
}

title {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace italic bold;
  font-size: 40px;
}

.techbytesTitle-public {
  color:#993399;
  font-size: larger;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.publicDataTiny {
  color: #000000;
  font-size: 7px;
  text-align:left;
  padding-bottom: 0%;
  list-style: none;
  position: fixed;
  bottom: 0;
  left: 0;
}
.publicData {
  color: #4CAF50;
  font-size: 15px;
  text-align:left;
  padding-bottom: 0%;
  list-style: none;
}

.publicData {
  position: absolute;
  top: 20px; 
  right: 20px; 
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.publicData li {
  opacity: 50;
  transition: opacity 0.5s ease;
}

.publicData li:nth-child(1) { transition-delay: 0s; }
.publicData li:nth-child(2) { transition-delay: 0.5s; }
.publicData li:nth-child(3) { transition-delay: 1s; }
/* Add more nth-child rules for additional list items */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginBtn {

}

/* PRIVATE */

.techbytesTitle {
  color:#993399;
  font-size: larger;
  top: 0;
  left: 0;
}

.logo-private {
  height: 15vmin;
  pointer-events: none;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.App-link {
  color: #61dafb;
  margin-left: 25px;
  margin-right: 25px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.welcome-Tag {
  font-family: DejaVu Sans Mono, monospace;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0vh;
  flex-direction: column;
}
.welcome-Tagline {


}

.navBar-container-private {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
profile-div {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace italic bold;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profileContent {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace italic bold;
  font-size: 18px;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileBtn {

}